/* eslint-disable max-classes-per-file */
// import Utils from '@/libs/utils';
import { WorkspaceObjectModel } from './WorkspaceObjectModel';

export enum State {
  INIT = 'INIT',
  SCHEDULED = 'SCHEDULED',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  TERMINATED = 'TERMINATED',
  ERROR = 'ERROR'
}

export interface StateRecord {
  state: State;
  datetime: string;
}

export interface ValidData {
  startTimeHour: number;
  startTimeMin: number;
  endTimeDay: number;
  endTimeHour: number;
}

export interface TimeMapValue {
  startTime: string | null;
  endTime: string | null;
  scheduledTime: string | null;
  duration: number;
  recordCode: string | null;
}

export interface MonitorMapValue {
  noScreenshot: boolean;
  noSwitchApp: boolean;
  noPhoneCall: boolean;
}
export interface PushedQuizGroupRecord{
  groupId: string[];
  scheduledTime: string;
  recordCode: string;
}

export interface SentDataRecord {
  quizGroup: WorkspaceObjectModel[];
  scheduledTime: string;
  recordCode: string;
}

export interface DayMapValue {
  id: string;
  date: string;
}

export interface MultipleDatesAttributes {
  highlight: HighlightCalendar;
  dates: string;
  popover: {
    label: string;
  };
}

export interface HighlightCalendar {
  color: string;
  fillMode: string;
}

export interface QuizEventStateModel {
  stateHistory: StateRecord[] | null; // under development
}

export interface UpdateQuizEventPayloadModel {
  name?: string;
  // startTime?: string;
  // endTime?: string;
  // scheduledTime?: string;
  allowRetake?: boolean;
  quizGroupIdList?: string[];
  memberGroupIdList?: string[];

  timeMap?: object;

  monitorMap?: object;

  onePush?: boolean;

  period?: string;

  frequency?: number;

  weekdays?: string[];

  validData?: ValidData;

  eyeballDetect?: boolean;
}

export class UpdateQuizEventPayload implements UpdateQuizEventPayloadModel {
  // startTime?: string; // 不使用了

  // endTime?: string; // 不使用了

  // scheduledTime?: string; // 不使用了

  allowRetake?: boolean; // 不使用了

  name?: string;

  quizGroupIdList?: string[];

  memberGroupIdList?: string[];

  timeMap?: Map<string, TimeMapValue>;

  monitorMap?: Map<string, MonitorMapValue>;

  onePush?: boolean;

  period?: string;

  frequency?: number;

  weekdays?: string[];

  validData?: ValidData;

  eyeballDetect?: boolean;

  constructor(name: string | null,
    // startTime: string | null,
    // endTime: string | null,
    // scheduledTime: string | null,
    allowRetake: boolean | null,

    quizGroups: WorkspaceObjectModel[] | null,
    memberGroups: WorkspaceObjectModel[] | null,
    timeMap: Map<string, TimeMapValue>| null,
    monitorMap: Map<string, MonitorMapValue>| null,
    onePush: boolean,
    eyeballDetect: boolean,
    period: string,
    validData: ValidData,
    frequency: number,
    weekdays: string[]) {
    if (name) {
      this.name = name;
    }

    if (timeMap !== null) {
      this.timeMap = timeMap;
    }
    if (monitorMap !== null) {
      this.monitorMap = monitorMap;
    }
    if (onePush !== null) {
      this.onePush = onePush;
    }
    if (period !== null) {
      this.period = period;
    }

    // if (startTime) {
    //   this.startTime = startTime;
    // }

    // if (endTime) {
    //   this.endTime = endTime;
    // }

    // if (scheduledTime) {
    //   this.scheduledTime = scheduledTime;
    // }

    if (quizGroups) {
      this.quizGroupIdList = [];
      for (let i = 0; i < quizGroups.length; i += 1) {
        this.quizGroupIdList.push(quizGroups[i].uuid);
      }
    }

    if (memberGroups) {
      this.memberGroupIdList = [];
      for (let i = 0; i < memberGroups.length; i += 1) {
        this.memberGroupIdList.push(memberGroups[i].uuid);
      }
    }
    if (validData !== null) {
      this.validData = validData;
    }
    if (frequency !== null) {
      this.frequency = frequency;
    }
    if (weekdays !== null) {
      this.weekdays = weekdays;
    }

    if (allowRetake !== null) {
      this.allowRetake = allowRetake;
    }

    if (eyeballDetect !== null) {
      this.eyeballDetect = eyeballDetect;
    }
  }
}

// === new models for v0.4 === //
export interface QuizEventPreviewModel extends WorkspaceObjectModel {
  // stateHistory: StateRecord[] | null;
  // startTime: string;
  // endTime: string;
  // quizGroupCount: number;
  // memberGroupCount: number;
  allowRetake: boolean;

  shortId: string;
  scheduledTime: string;
  PushedQuizGroupList: PushedQuizGroupRecord[];
  TimeMap: Map<string, TimeMapValue>;
  monitorMap: Map<string, MonitorMapValue>;
}

// TODO: rename to QuizEventModel after deleting current QuizEventModel
export interface QuizEventModelv4 extends WorkspaceObjectModel {
  startTime: string | null;
  endTime: string | null;
  shortId: string;
  scheduledTime: string | null;
  stateHistory: StateRecord[] | null;
  // 以上可能用不到了

  allowRetake: boolean;
  quizGroups: WorkspaceObjectModel[] | null; // cloud be quizgroup, folder, shared object
  memberGroups: WorkspaceObjectModel[] | null; // cloud be membergroup, folder, shared object
  timeMap: Map<string, TimeMapValue> | null;
  monitorMap: Map<string, MonitorMapValue> | null;
  onePush: boolean;
  eyeballDetect: boolean;
  period: string;
  frequency: number;
  weekdays: string[];
  validData: ValidData;
  pushedQuizGroupList: PushedQuizGroupRecord[];
}
