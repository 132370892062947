import api from '@/apis/BaseApi';
import {
  QuizEventModelv4, QuizEventStateModel, UpdateQuizEventPayload,
} from '@/apis/models/QuizEventModel';
import i18n from '@/i18n';

export default class QuizEventApi {
  /**
   * Create a new QuizEvent for a workspace
   */
  static async create(parentFolderId: string): Promise<QuizEventModelv4> {
    const body = {
      parentId: parentFolderId,
      name: i18n.t('未命名測驗'),
    };
    const result = await api.post('workspace/quiz-events/', body);
    return result.data.quizEvent;
  }

  /**
   * Get a specific QuizEvent in a workspace
   *
   * @param quizEventId
   */
  static async getQuizEvent(quizEventId: string): Promise<QuizEventModelv4> {
    const result = await api.get(`workspace/quiz-events/${quizEventId}`);
    return result.data.quizEvent;
  }

  /**
   * Get current state of a QuizEvent
   *
   * @param workspaceId
   * @param quizEventId
   */
  static async getQuizEventState(quizEventId: string): Promise<QuizEventStateModel> {
    const result = await api.get(`workspace/quiz-events/${quizEventId}/state`);
    return result.data.quizEventState;
  }

  /**
   * Update the data of a QuizEvent by a quizEvent object
   * if some values in quizEvent are not set (null or undefined), these null values will not be updated (remind the same in backend database)
   *
   * When the QuizEvent is locked, only startTime, endTime and name could be updated.
   * If you try to update other properties when QuizEvent is locked, you will receive "409 Conflict" error.
   *
   * @param workspaceId
   * @param quizEventId
   * @param quizEvent the data used to update QuizEvent specified by quizEventId
   */
  static async update(quizEventId: string, quizEvent: QuizEventModelv4): Promise<void> {
    const {
      metadata, quizGroups, allowRetake, memberGroups, timeMap, onePush, eyeballDetect, period, validData, frequency, weekdays, monitorMap,
    } = quizEvent;
    // const {
    //   metadata, startTime, endTime, scheduledTime, quizGroups, memberGroups, allowRetake,
    // } = quizEvent;
    // const updatePayload = new UpdateQuizEventPayload(metadata.name, startTime, endTime, scheduledTime, quizGroups, memberGroups, allowRetake);
    const updatePayload = new UpdateQuizEventPayload(metadata.name, allowRetake, quizGroups, memberGroups, timeMap, monitorMap, onePush, eyeballDetect, period, validData, frequency, weekdays);
    const body = {
      ...updatePayload,
    };
    await api.put(`workspace/quiz-events/${quizEventId}`, body);
  }

  static async sendNow(quizEventId: string): Promise<void> {
    await api.post(`workspace/quiz-events/${quizEventId}/send`, {});
  }
}
